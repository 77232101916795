<template>
  <BannerStyle1 :list="bannerList" :position="5"></BannerStyle1>
  <div class="usually-box">
    <section class="special top-box usually-box">
      <h3 class="title">平台特色</h3>
      <ul class="flex-between customize-list-style1">
        <li class="shadow-hover" style="width: 350px;" v-for="(item, index) in specitalList" :key="index">
          <img :src="item.img" alt="">
          <div>{{item.name}}</div>
        </li>
      </ul>
    </section>
  </div>
  <section class="trade-box usually-box">
    <h3 class="title">行业痛点</h3>
    <ul class="flex-between customize-list-style1">
      <li v-for="(item, index) in tradeList" :key="index">
        <img class="trade-img" :src="item.img" alt="">
        <h3>{{item.name}}</h3>
        <p>{{item.desc}}</p>
        <div class="anim-bottom  introduce-box">
          <h3>{{item.name}}</h3>
          <p>{{item.desc}}</p>
        </div>
      </li>
    </ul>
  </section>
  <section class="goodness-box usually-box">
    <h3 class="title">平台优势</h3>
    <div class="goodness-content">
      <ul class="menu-area">
        <li v-for="(item, index) in goodnessList" :key="index" :class="{'on':item.id === nowGoodsness}" @mouseover="mouseFun(item)">
          <div class="menu-name">
            {{item.name}}
          </div>
          <ul class="content-area">
            <li v-for="(det, detIndex) in item.list" :key="detIndex">
              <img :src="det.img" alt="">
              <h3>{{det.name}}</h3>
              <p class="desc">
                {{det.desc}}
              </p>
              <div class="introduce-box">
                <h3>{{det.name}}</h3>
                <p>{{det.desc}}</p>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </section>
  <section class="function-box usually-box">
    <h3 class="title">功能特性</h3>
    <SingleLine :list="funList"></SingleLine>
  </section>
</template>
<script setup>
import { ref, reactive, computed } from 'vue'
import SingleLine from '@/components/SingleLine'
const bannerList = [
  {
    img: require("@/assets/product/trade/banner.png"),
    title: '海马链产业要素交易平台',
    desc: '供应链、区块链、价值链多链融合业务模式的产业数字化交易平台',
    btnText: '预约演示',
    url: '/post/contact',
  }
]

const specitalList = [
  {
    name: '可信区块链技术加持',
    img: require('@/assets/product/trade/special1.png'),
  },
  {
    name: '经历真实行业验证',
    img: require('@/assets/product/trade/special2.png'),
  },
  {
    name: '支持二次定制化',
    img: require('@/assets/product/trade/special3.png'),
  },
]

const goodnessList = [
  {
    id: 1,
    name: '适用性视角',
    list: [
      {
        img: require('@/assets/product/trade/menu1-img1.png'),
        name: '弹性架构',
        desc: '以企业的弹性需求和产业场景需求为核心搭建的中后台基础架构管理平台，能够基于产业的发展状况进行技术分析、辅助决策和弹性设置，最大限度地融合产业、企业的业务模式。'
      },
      {
        img: require('@/assets/product/trade/menu1-img2.png'),
        name: '超融合架构',
        desc: '以超融合技术搭建的基础架构平台，能够最大限度地基于安全整合云、网、链、边、端等各种技术资源，采用AI及大数据技术，构建围绕场景和业务的开放式集合管理中心。'
      },
      {
        img: require('@/assets/product/trade/menu1-img3.png'),
        name: '动态进化架构',
        desc: '基于人工智能、大数据等技术的充分运用，能够围绕平台数据进行不间断的学习和分析，并依据不同需求维度提供后台的技术报告，为平台的实时进化、优化提供数据分析依据。'
      },
      {
        img: require('@/assets/product/trade/menu1-img2.png'),
        name: '全栈式架构',
        desc: '面向产业内技术需求和技术发展趋势的全景图，基于产业、企业的实际需要，采用集合式磋商的创新模式进行选型、评价和链接，为企业的实用需求提供可行的全栈式、进程式技术解决方案及其选购通道。'
      },
    ]
  },
  {
    id: 2,
    name: '专业性视角',
    list: [
      {
        img: require('@/assets/product/trade/menu2-img1.png'),
        name: '协同共建模式',
        desc: '集合政府、产业组织、重点企业的力量协同共建平台及运营实体，通过共建完成平台的实用性、适用性构建，为平台的顺畅运营提供必要条件。'
      },
      {
        img: require('@/assets/product/trade/menu2-img2.png'),
        name: '产业优先模式',
        desc: '基于产业级不同组织的共性需求和业务模型，整合产业标准化资源，推动要素集合的数据标准化建设和相关服务，面向全产业提供核心业务的构建和链接专业性、社会性服务资源。'
      },
      {
        img: require('@/assets/product/trade/menu2-img3.png'),
        name: '共同成长模式',
        desc: '平台建设以交付租赁和运营分账的模式并行推广，建设企业和运营企业长期合作共同成长，长期提供技术运维、优化升级的各项服务，免除平台运营、发展的后续投入忧虑。'
      },
      {
        img: require('@/assets/product/trade/menu2-img4.png'),
        name: '专家服务模式',
        desc: '一站式运营为平台运营者及其用户提供一系列部署、升级、运营的服务支撑，提供7*24 的远程技术支持及人工验证、高危漏洞验证，重保服务等多种专家型服务。'
      },
    ]
  },
  {
    id: 3,
    name: '安全性视角',
    list: [
      {
        img: require('@/assets/product/trade/menu3-img1.png'),
        name: '核心数据保护',
        desc: '存储在区块链上，利用高冗余的数据库保障信息的完整性，利用密码学的相关原理进行数据验证，保证不可篡改，同时运用多私钥进行访问权限控制。'
      },
      {
        img: require('@/assets/product/trade/menu3-img2.png'),
        name: '入侵检测保护',
        desc: '提供多锚点检测能力，能够实时准确感知入侵事件，发现失陷主机，并对业务系统“零”影响；提供了包括自动封停、手动隔离、黑/ 白名单和自定义处理任务等多种处理方式。'
      },
      {
        img: require('@/assets/product/trade/menu3-img3.png'),
        name: '云防御技术',
        desc: '采用反向代理技术实现替身式防护，采用规则检测、机器学习、语义分析等智能检测技术，识别并拦截攻击行为，避免网站服务器被恶意入侵，保障业务的核心数据安全。'
      },
      {
        img: require('@/assets/product/trade/menu3-img4.png'),
        name: '安全监测技术',
        desc: '针对平台进行可用性监测、完整性监测及威胁深度检测，配以灵活的通知告警，全面掌握网站状况，快速发现故障，降低影响减少损失。'
      },
    ]
  },
]

let nowGoodsness = ref(1)

const tradeList = [
  {
    name: '流程僵化',
    img: require('@/assets/product/trade/trade1.png'),
    desc: '当今的供应链运营结构呈“线性”模式。 供应链上游出现干扰时，必将影响下游。'
  },
  {
    name: '供应链脆弱',
    img: require('@/assets/product/trade/trade2.png'),
    desc: '制造业比其他行业更容易出现延误和中断，原因是其他行业对制造业依赖。'
  },
  {
    name: '数据可信性差',
    img: require('@/assets/product/trade/trade3.png'),
    desc: '缺乏自动化和数据安全挑战正在阻碍企业信息的流动，数据供应链中存在诸多薄弱环节。'
  },
]

const funList = [
  {
    name: '产业大脑',
    img: require('@/assets/product/trade/fun1.png'),
    desc: '基于数据的统计、计算、分析和趋势等为政府、产业组织、社会、市场等提供服务。'
  },
  {
    name: '融链引擎',
    img: require('@/assets/product/trade/fun2.png'),
    desc: '基于产业链、供应链、价值链为经营的主体、客体、关系等提供定义和相关设置。'
  },
  {
    name: '数据引擎',
    img: require('@/assets/product/trade/fun3.png'),
    desc: '基于区块链的分布式数据库为数据安全存储、管理和应用提供隐私计算接口。'
  },
  {
    name: '要素系统',
    img: require('@/assets/product/trade/fun4.png'),
    desc: '基于产业、企业、市场的需求为产业和数据要素的交易交割提供数字经济服务平台。'
  },
  {
    name: '信用系统',
    img: require('@/assets/product/trade/fun5.png'),
    desc: '基于可信数据验证和算法为企业信用评价和金融服务等提供数据和风控的相关服务。'
  },
  {
    name: '边缘终端',
    img: require('@/assets/product/trade/fun6.png'),
    desc: '基于数据的统计、计算、分析和趋势等为政府、产业组织、社会、市场等提供服务。'
  },
]

function mouseFun(item) {
  nowGoodsness.value = item.id
}

</script>
<style lang='scss' scoped>
@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}
.title {
  padding: 92px 0 60px;
  font-size: 42px;
  text-align: center;
  color: #202020;
}
.special {
  margin-top: -150px;
  padding: 0 60px 60px;
  > ul {
    > li {
      font-size: 18px;
      color: #808080;
      text-align: center;
      > img {
        margin-bottom: 24px;
        height: 72px;
      }
    }
  }
}

.goodness-box {
  .goodness-content {
    position: relative;
  }
  .menu-area {
    > li {
      display: flex;
      $width: 400px;
      .menu-name {
        flex-shrink: 0;
        width: $width;
        height: 240px;
        font-size: 28px;
        text-align: center;
        line-height: 240px;
      }
      .content-area {
        position: absolute;
        top: 0;
        left: $width;
        z-index: 5;
        width: calc(100% - $width);
        display: flex;
        flex-wrap: wrap;
        > li {
          position: relative;
          overflow: hidden;
          box-sizing: border-box;
          padding: 37px 40px 67px;
          width: 50%;
          font-size: 28px;
          text-align: center;
          color: #202020;
          background-color: #fff;
          > img {
            height: 64px;
          }
          > h3 {
            margin: 22px 0 26px;
            font-weight: normal;
            text-align: center;
          }
          > p {
            font-size: 16px;
            color: #808080;
          }
          &:hover {
            .introduce-box {
              transform: translateY(0);
              transition: transform 0.5s;
            }
          }
        }
        .introduce-box {
          display: flex;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 10;
          align-items: center;
          align-content: center;
          flex-wrap: wrap;
          padding: 0 15px;
          background-color: #8080ff;
          transform: translateY(100%);
          > h3 {
            margin: 10px 0;
            width: 100%;
            line-height: 1.6em;
            color: #fff;
          }
          > p {
            font-size: 18px;
            line-height: 1.8em;
            color: #fff;
          }
        }
      }
    }
    .on {
      color: #fff;
      background-color: #0766e1;
      .content-area {
        z-index: 10;
      }
    }
  }
}

.function-box {
  margin-top: 30px;
  padding-bottom: 80px;
  background: #436fdd;
  > h3 {
    color: #fff;
  }
}
.trade-box {
  > ul {
    > li {
      position: relative;
      box-shadow: 0 0 10px 5px rgb(0 0 0 / 20%);
      &:last-child {
        transform: scale(1.05);
      }
      &:nth-child(2) {
        transform: scale(1.15);
      }
      &:nth-child(3) {
        transform: scale(1.25);
      }
    }
  }
}
</style>